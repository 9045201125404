<template>
    <div>
        <LoaderDefault v-if="isLoading" />
        <div v-else>
            <DefaultHeader2 :routeBack="'/News'" :titleHeader="'Formulario de registro'" />
            <section class="content-form">
                <div class="content-inputs" id="content-inputs">
                    <label for="title_new" id="number-pocess-label">Título de la noticia *</label>
                    <input v-model="dataInfoNew.title" class="input" name="title_new" type="text"
                        placeholder="Título de la noticia" id="title_new" required>
                </div>

                <div class="content-inputs" id="content-inputs">
                    <label for="description_new">Descripción de la noticia *</label>
                    <input v-model="dataInfoNew.description" class="input" name="description_new" type="text"
                        placeholder="Descripción de la noticia" id="description_new" required>
                </div>

                <div class="content-inputs" id="content-inputs">
                    <label for="date_new">Fecha *</label>
                    <input id="date_new" v-model="dataInfoNew.date" class="input" name="date_new" type="date"
                        placeholder="fecha" required>
                </div>

            </section>
            <hr>

            <section class="sec-anexos" id="sec-anexos">
                <div class="header-sec-anexos">
                    <h1 class="title-section-anexos">Subir imagen de la noticia</h1>
                    <i class="fa-solid fa-circle-info"></i>
                </div>

                <div class="content-list-items-add">
                    <div v-for="(item, index) in listImageNew" :key="index">
                        <div class="content-item">
                            <h2 class="name-item-added">{{ item.name }}</h2>
                            <i class="fa-solid fa-image icon-img"></i>
                            <i class="el-icon-close text-danger icon-added" @click="showConfirmation(item)"></i>
                        </div>
                    </div>
                </div>

                <div class="content-form">
                    <div class="content-inputs" id="content-inputs">
                        <label class="label" id="label" for="name">Nombre *</label>
                        <input id="name" v-model="dataImgNew.name" class="input input-anexo" name="name" type="text"
                            placeholder="Nombre" required>
                    </div>
                    <div class="content-inputs" id="content-inputs">
                        <label class="label" id="label" for="description">Descripción *</label>
                        <input id="description" v-model="dataImgNew.description" class="input input-anexo"
                            name="description" type="text" placeholder="Descripción" required>
                    </div>

                    <div class="div-select-custom">
                        <input id="file-5" type="file" @click="loadFilePdf" @change="loadFilePdf()" name="file-5"
                            class="inputfile inputfile-5 input-anexo" required />
                        <label id="label" for="file-5" class="label-file label">
                            <figure>
                                <svg xmlns="http://www.w3.org/2000/svg" class="iborrainputfile" width="20" height="17"
                                    viewBox="0 0 20 17">
                                    <path
                                        d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z">
                                    </path>
                                </svg>
                            </figure>
                        </label>
                        <h6 class="name-pdf-selected label" id="name-pdf-selected">Seleccionar imagen *</h6>
                    </div>
                </div>
                <button class="btn-save-item" @click="addImgToArray()">Guardar imagen</button>
            </section>
            <hr>

            <button class="btn-save-data" @click="saveNew()">Registrar
                noticia</button>
        </div>

    </div>
</template>

<script>
import { db, storage } from '../../../../firebaseConfig';
import LoaderDefault from '../../../components/Loaders/LoaderDefault.vue';
import DefaultHeader2 from '../../../components/Headers/DefaultHeader2'

export default {
    components: {
        LoaderDefault,
        DefaultHeader2
    },
    data() {
        return {
            listImageNew: [],
            isLoading: false,
            selectedImg: File,
            dataImgNew: {
                _idName: '',
                name: '',
                description: '',
                urlImage: 'url',
                file: ''
            },
            dataInfoNew: {
                _id: '_id',
                title: '',
                description: '',
                status: 'active',
                date: '',
                images: []
            }
        };
    },

    methods: {
        navToIndexTender() { this.$router.push('/News') },

        addImgToArray() {
            if (this.validaFormSecondForm()) {
                this.dataImgNew.file = this.selectedFile
                this.listImageNew.push(this.dataImgNew)
                this.dataImgNew = {}
            }
        },

        removeImgToArray(itemAnexo) {
            const index = this.listImageNew.indexOf(itemAnexo);
            if (index > -1) this.listImageNew.splice(index, 1);
        },

        loadFilePdf() {
            const input = document.getElementById('file-5');
            input.accept = "image/*";
            input?.addEventListener('change', (e) => {
                const target = e.target;
                // this.selectedFile = File;
                if (target.files && target.files.length > 0) {
                    const nameSelected = document.getElementById('name-pdf-selected');
                    nameSelected.innerHTML = '' + target.files[0].name;
                    this.selectedFile = target.files[0]                    
                }
            });
        },

        async saveNew() {
            if (this.validaFormFirtForm()) {
                if (this.listImageNew.length > 0) {
                    this.isLoading = true
                    try {
                        const collectionRef = db.collection('news');
                        const docRef = await collectionRef.add(this.dataInfoNew);
                        const docId = docRef.id;
                        const storageRef = storage.ref();
                        const imagePromises = this.listImageNew.map(async (imgNew) => {
                            const imageRef = storageRef
                                .child('imgNews')
                                .child(docId)
                                .child(imgNew.name);

                            await imageRef.put(imgNew.file);
                            const imageUrl = await imageRef.getDownloadURL();
                            const imgNewData = {
                                _idName: imgNew.name,
                                name: imgNew.name,
                                description: imgNew.description,
                                urlImage: imageUrl
                            };
                            return imgNewData;
                        });

                        this.listImageNew = await Promise.all(imagePromises);

                        await collectionRef.doc(docId).update({
                            _id: docId,
                            images: this.listImageNew
                        });
                        this.$swal
                            .fire({
                                title: 'Noticia registrada',
                                text: 'Se ha registrado una nueva noticia con exito',
                                icon: 'success',
                                showCancelButton: false,
                                confirmButtonText: 'Aceptar',
                                confirmButtonColor: "#45A825",
                            })
                            .then((result) => {
                                if (result.value) this.navToIndexTender()
                                else this.navToIndexTender()
                            });
                        this.isLoading = false
                    } catch (error) {                        
                        throw error;
                    }
                } else
                    this.$swal
                        .fire({
                            title: 'Información importante',
                            text: 'Para lograr el almacenamiento correcto de una nueva noticia, es necesario ' +
                                'agregar minimo una imagen',
                            icon: 'warning',
                            showCancelButton: false,
                            confirmButtonText: 'Aceptar',
                            confirmButtonColor: "#45A825",
                            timer: 5000
                        })
            }
        },

        showConfirmation(itemAnexo) {
            this.$swal
                .fire({
                    title: 'Eliminar imagen',
                    text: '¿Estás seguro de realizar esta acción?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: "#45A825",
                    cancelButtonText: 'Cancelar',
                })
                .then((result) => {
                    if (result.value) this.removeImgToArray(itemAnexo);
                });
        },

        validaFormFirtForm() {
            const inputs = document.getElementsByTagName('input');
            const label = document.getElementsByTagName('label');

            for (let i = 0; i < inputs.length - 3; i++) {
                if (inputs[i].hasAttribute('required') && inputs[i].value.trim() === '') {
                    const label = document.querySelector(`label[for="${inputs[i].id}"]`);
                    label.style.color = 'red';
                    label.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    return false;
                } else {
                    inputs[i].style.backgroundColor = '';
                    label[i].style.color = ''
                }
            }
            return true;
        },

        validaFormSecondForm() {
            const inputs = document.querySelectorAll('.input-anexo');
            const labels = document.querySelectorAll('.label');
            const elementSelectPdf = document.getElementById('name-pdf-selected')

            for (let i = 0; i < inputs.length; i++) {
                if (inputs[i].hasAttribute('required') && inputs[i].value.trim() === '') {
                    const label = document.querySelector(`label[for="${inputs[i].id}"]`);
                    label.style.color = 'red';
                    elementSelectPdf.style.color = "red"
                    label.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    return false;
                } else {
                    inputs[i].style.backgroundColor = '';
                    labels[i].style.color = ''
                    elementSelectPdf.style.color = ''
                }
            }
            return true;
        },
    },


}


</script>
<style lang="css">
.header-register {
    margin-top: 50px;
    display: flex;
    align-items: center;
}

.back-route {
    margin-left: 15px;
    margin-right: 30px;
}

.icon-back {
    font-size: 40px;
    cursor: pointer;
}

.title-section {
    font-weight: bold;
}

/* SECTION INPUTS FORM */
.content-form {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 20px;
}

p {
    margin: 0;
    padding: 0;
}

.content-inputs {
    width: 30%;
    /* background-color: blue; */
    margin: 5px;
    text-align: start;
    overflow: hidden;
    padding: 10px;
}

label {
    width: 100% important;
}

.input {
    width: 100%;
    height: 60px;
    border-radius: 10px;
    border: 0;
    padding-left: 15px;
    padding-right: 15px;
    background-color: #4d9bf53d;
    outline: none;
    box-shadow: #d8d7d7 2px 4px 8px;
}

.input-date-anexo {
    margin-left: 160px;
}

/* SECTION ANEXOS */

.header-sec-anexos {
    display: flex;
    align-items: center;
    margin-left: 15px;
}

.title-section-anexos {

    font-size: 25px;
    font-weight: bold;
    margin: 0px 10px 0px 0px;
}

/* LIST ITEMS ANEXOS */

.content-list-items-add {
    margin-top: 30px;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* background-color: #4d9bf521; */
}

.content-item {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #75ff4757;
    border-radius: 8px;
    margin: 10px;
    box-shadow: #46a82566 2px 4px 8px;
}

.name-item-added {
    margin: 0;
    padding: 10px;
    font-size: 20px;
    font-weight: bold;
    text-transform: capitalize;
}

.icon-img {
    margin-right: 10px;

}

.icon-x {
    margin-right: 10px;
    color: rgb(221, 9, 9);
    font-weight: bold;
    cursor: pointer;
}

.label-file {
    margin-top: 30px;
}

/* Ocultar el input de tipo file por defecto */
.inputfile-5 {
    display: none;
}


.inputfile-5+label {
    color: #c39f77;
}

.inputfile-5:focus+label,
.inputfile-5.has-focus+label,
.inputfile-5+label:hover {
    color: #005dca;
}

.inputfile-5+label figure {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: #4d9bf5;
    display: block;
    padding: 15px;
    margin: 0 auto 10px;
}

.inputfile-5:focus+label figure,
.inputfile-5.has-focus+label figure,
.inputfile-5+label:hover figure {
    background-color: #005dca;
}

.inputfile-5+label svg {
    width: 100%;
    height: 100%;
    fill: #fff;
    cursor: pointer;
}

.btn-save-anexo {
    display: block;
    margin: 30px auto;
    border: 0;
    color: white;
    background-color: #46a825c3;
    padding: 10px;
    border-radius: 10px;
    font-weight: bold;
}

.btn-save-tender {
    display: block;
    /* margin-top: 20px;
    margin-bottom: 100px; */
    margin: 30px auto;
    border: 0;
    color: white;
    background-color: #45A825;
    padding: 10px;
    border-radius: 10px;
    font-weight: bold;
    width: 40%;
}

.label-file {
    cursor: pointer;
}

.progress {
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px auto;
}
</style>